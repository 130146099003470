import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics, logEvent } from './firebase';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Log page view event
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title: document.title
    });
  }, [location]);
};